import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  // 認証情報
  const auth = useAuth();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  const [userName, setUserName] = useState();
  // 初期表示処理
  useEffect(() => {
    setUserName(auth.auth.user_name);
  }, [auth]);

  // メニュー表示をおこなうか？
  const isDisplayMenu = (prop) => {
    if (!prop.icon) {
      return false;
    }
    // 権限なしは表示
    if (!prop.auth) {
      return true;
    }

    // 利用者の権限と一致する場合のみ表示
    return auth.auth.kbn && prop.auth.some((a) => auth.auth.kbn.includes(a));
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: 'url(' + image + ')',
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <div className="logo-img">
            <img src={require('assets/img/logo.png')} alt="..." />
          </div>
          <p style={{ marginTop: '4px', marginBottom: 'unset' }}>
            株式会社 あて
            <br />
            <span style={{ fontSize: '70%' }}>{userName}さん</span>
          </p>
        </div>
        <Nav>
          {routes
            .filter((prop) => isDisplayMenu(prop))
            .map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade ? 'active active-pro' : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => sessionStorage.clear()} // メニュークリック時にセッションストレージをクリア
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
