import { DataMap } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import { CellType, CellRange } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import TextComponent from 'components/Input/TextComponent';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProcessLableComponent } from 'components/Input/ProcessLableComponent';
import KoujiStatusModal from 'views/KoujiDaicho/KoujiStatusModal';

/** 工事台帳一覧 */
function KoujiDaichoList() {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();
  const master = useMaster();
  const restApi = useRestApi();

  var planerMap = new DataMap(master.planer, 'id', 'name');

  const processDict = master.process.reduce((acc, item) => {
    acc[item.PROCESS_CD] = item; // `id` をキーに設定
    return acc;
  }, {});

  // グリッド
  const flexGrid = useRef();

  // 工事一覧情報
  const [koujiList, setKoujiList] = useState(new CollectionView());

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({});
  // 工事状況モーダルパラメタ
  const [koujiStatusModalParam, setKoujiStatusModalParam] = useState({});

  // 初期表示
  useEffect(() => {
    // 他画面から戻ってきた時に復元する
    const sessionKoujiDaichoInfo = sessionStorage.getItem('koujiDaichoInfo');
    if (sessionKoujiDaichoInfo) {
      const koujiDaichoInfo = JSON.parse(sessionKoujiDaichoInfo);
      const seikyuNo = koujiDaichoInfo?.koujiSeikyuNo;
      const _searchCondition = koujiDaichoInfo?.searchCondition;
      setSearchCondition(_searchCondition);
      // 検索処理
      search(_searchCondition, seikyuNo);

      // 初期表示は1年前からのデータを表示
    } else {
      const date = new Date();
      let year = date.getFullYear() - 1;
      let month = date.getMonth() + 1;
      const _searchCondition = {
        START_KEI_DATE: `${year}/${month}/01`,
        END_KEI_DATE: '',
      };
      setSearchCondition(_searchCondition);
      // 検索処理
      search(_searchCondition);
    }

    // ダブルクリックイベント
    if (flexGrid.current) {
      const grid = flexGrid.current.control;
      grid.hostElement.addEventListener('dblclick', (e) => {
        const hit = grid.hitTest(e);
        if (hit.panel == grid.cells) {
          const item = grid.rows[hit.row].dataItem;
          setKoujiStatusModalParam({
            show: true,
            item: item,
          });
        }
      });
    }
  }, []);

  // 検索処理
  const search = useCallback(
    (condition, seikyuNo) => {
      restApi.post('/api/kouji/list', condition, (data) => {
        setKoujiList(new CollectionView(data.koujiList));

        // 戻ってきたときに選択行を復元
        if (seikyuNo) {
          const index = data.koujiList.indexOf(
            data.koujiList.find((x) => x['SEIKYU_NO'] == seikyuNo)
          );
          if (flexGrid.current) {
            setTimeout(() => {
              const grid = flexGrid.current.control;
              grid.select(new CellRange(index), true);
            }, 200);
          }
        }
      });
    },
    [searchCondition]
  );

  // 検索条件の編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 顧客情報ボタン
  const handleOnClickClientInfo = (item) => {
    // 選択行の工事番号を保持
    sessionStorage.setItem(
      'koujiDaichoInfo',
      JSON.stringify({ koujiSeikyuNo: item.SEIKYU_NO, searchCondition: searchCondition })
    );

    navigate('/admin/ClientInfo', {
      state: {
        clientId: item.CLIENT_CD,
        seikyuNo: item.SEIKYU_NO,
      },
    });
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    search(searchCondition);
  };

  return (
    <Card className="m-0 table-container">
      <Card.Body>
        <Row>
          <Col className="pr-1">
            <Form.Group>
              <label>契約日</label>
              <br />
              <TextComponent
                type="date"
                value={searchCondition.START_KEI_DATE ?? ''}
                onChange={handleChange('START_KEI_DATE')}
              ></TextComponent>
              <span className="ml-2 mr-2">～</span>
              <TextComponent
                type="date"
                value={searchCondition.END_KEI_DATE ?? ''}
                onChange={handleChange('END_KEI_DATE')}
              ></TextComponent>
              <Button
                variant="success"
                className="btn-fill ml-3"
                size="sm"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="table-scroll" style={{ height: 'calc(100vh - 200px)' }}>
              <FlexGrid
                className="kouji-daicho-grid"
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={koujiList}
                selectionMode={'ListBox'}
                headersVisibility={'Column'}
                style={{ height: '100%', pointerEvents: 'auto' }}
                loadedRows={(grid) => {
                  grid.rows.forEach((ele) => {
                    ele.height = 30;
                  });
                }}
              >
                <FlexGridFilter />
                <FlexGridColumn
                  binding="SEIKYU_NO"
                  header="工事No"
                  format={'d'}
                  width={68}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="PRCESS_CD"
                  header="工程"
                  width={100}
                  allowResizing={false}
                  align="center"
                >
                  <FlexGridCellTemplate
                    binding="PRCESS_CD"
                    cellType="Cell"
                    template={(cell) => (
                      <ProcessLableComponent
                        process={processDict[cell.item.PROCESS_CD]}
                        size="small"
                      />
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn binding="KEI_DATE" header="契約日" width={88}></FlexGridColumn>
                <FlexGridColumn
                  binding="TAN_CD"
                  header="担当"
                  dataMap={planerMap}
                  width={68}
                ></FlexGridColumn>
                <FlexGridColumn binding="CL_NAME" header="顧客名" width={'*'}></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={'*'}></FlexGridColumn>
                <FlexGridColumn binding="MITSU_KIN" header="売上金額" width={100}></FlexGridColumn>
                <FlexGridColumn
                  binding="QUOTE_GENKA"
                  header="当初予定原価"
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn binding="CYU_GENKA" header="注文金額" width={100}></FlexGridColumn>
                <FlexGridColumn
                  binding="SEIKYU_GENKA"
                  header="請求金額"
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn binding="C_CNT" header="注文数" width={80}></FlexGridColumn>
                <FlexGridColumn binding="G_CNT" header="請求数" width={80}></FlexGridColumn>
                <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon-sm btn-fill"
                          variant="success"
                          title="顧客情報"
                          onClick={() => handleOnClickClientInfo(cell.item)}
                        >
                          person
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
              </FlexGrid>
              {/** 工事状況モーダル */}
              <KoujiStatusModal params={koujiStatusModalParam} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default KoujiDaichoList;
