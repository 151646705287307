import { DataMap } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextComponent from 'components/Input/TextComponent';

/** 工事状況モーダル画面 */
function KoujiDaichoList(props) {
  const location = useLocation();

  const restApi = useRestApi();

  // グリッド
  const flexGrid = useRef();

  // 注文一覧情報
  const [orderList, setOrderList] = useState(new CollectionView());
  // 請求一覧情報
  const [paymentList, setPaymentList] = useState(new CollectionView());
  // 工事情報
  const [koujiInfo, setKoujiInfo] = useState(new CollectionView([{}]));

  // 表示
  const [show, setShow] = useState(false);

  // 初期表示
  useEffect(() => {
    if (props.params?.show) {
      restApi.get(
        `/api/kouji/${props.params.item.CLIENT_CD}/${props.params.item.SEIKYU_NO}`,
        (data) => {
          // 注文一覧
          setOrderList(new CollectionView(data.orderList));
          // 請求一覧
          setPaymentList(new CollectionView(data.paymentList));
          // 工事情報
          setKoujiInfo(new CollectionView([props.params.item]));
          setShow(true);
        }
      );
    }
  }, [props.params]);

  // モーダルの閉じる処理
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '720px',
          },
        },
      }}
      open={show}
    >
      <DialogTitle className="p-3">工事状況</DialogTitle>
      <DialogContent className="px-0 py-0 pr-0 pl-0">
        <Card className="m-0">
          <Card.Body className="p-3">
            <Row>
              <Col className="pl-3">
                <Form.Group>
                  <label>工事名</label>
                  <br />
                  <TextComponent
                    type="text"
                    value={props.params?.item?.KOUJI_MEI}
                    disabled={true}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div>注文一覧</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ minHeight: '100px' }}>
                  <FlexGrid
                    itemsSource={orderList}
                    selectionMode={'None'}
                    headersVisibility={'Column'}
                    isReadOnly={true}
                  >
                    <FlexGridColumn binding="CYU_DATE" header="注文日" width={95}></FlexGridColumn>
                    <FlexGridColumn
                      binding="KOUJI_MEI"
                      header="工事名"
                      width="*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TRI_NAME"
                      header="注文先"
                      width="*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="CYU_KIN"
                      header="金額(税抜)"
                      width={100}
                      align={'right'}
                    ></FlexGridColumn>
                  </FlexGrid>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>請求一覧</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ minHeight: '100px' }}>
                  <FlexGrid
                    itemsSource={paymentList}
                    selectionMode={'None'}
                    headersVisibility={'Column'}
                    isReadOnly={true}
                  >
                    <FlexGridColumn binding="MONTH" header="請求年月" width={95}></FlexGridColumn>
                    <FlexGridColumn
                      binding="ITEM_NAME"
                      header="商品名"
                      width="*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TRI_NAME"
                      header="取引先"
                      width="*"
                      allowResizing={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="KINGAKU"
                      header="金額(税抜)"
                      width={100}
                      align={'right'}
                    ></FlexGridColumn>
                  </FlexGrid>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </DialogContent>
      <DialogActions className="p-3 d-flex justify-content-between">
        <div style={{ width: '500px' }}>
          <FlexGrid
            itemsSource={koujiInfo}
            selectionMode={'None'}
            headersVisibility={'Column'}
            isReadOnly={true}
            allowSorting={false}
          >
            <FlexGridColumn
              binding="MITSU_KIN"
              header="売上金額(税抜)"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="QUOTE_GENKA"
              header="当初予定原価(税抜)"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="CYU_GENKA"
              header="注文金額(税抜)"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="SEIKYU_GENKA"
              header="請求金額(税抜)"
              format="c0"
              width={'1*'}
            ></FlexGridColumn>
          </FlexGrid>
        </div>
        <Button variant="secondary" className="footer-button" onClick={handleClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default KoujiDaichoList;
